<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Tổng số người bị nạn</label>
            <b-form-input
              v-model="dataGeneral.totalWorkerAccident"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số lao động bị chết</label>
            <b-form-input
              v-model="dataGeneral.totalWorkerDeath"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số lao động bị thương nặng</label>
            <b-form-input
              v-model="dataGeneral.totalWorkerInjury"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },

  props: {
    dataGeneral: {
      type: Object,
      default: _ => {},
    },
  },

  data() {
    return {
      disableProp: false,
    }
  },
}
</script>

<style>

</style>
